<template>
   <div class="guanjia">
    <div class="sekeeper_banner">
      <div class="bac_img">
        <img src="../assets/web/newHomebgc.png" alt="" />
      </div>
      <el-row>
        <el-col :span="12"> 
          <div class="logoText">
            高明区知识产权</br>综合管理平台
          </div>
        </el-col>
        <el-col :span="12">
          <div class="sekeeper_login">
            <div class="s_flex">
              <div>
                <h3 class="line_h_30 ft_weight_800 title_color sq_tilte1">
                  <!-- 专利管家 -->
                </h3>
                <p class="sq_content sq_content_color line_h_26">
                  <!-- 企业知识产权管理系统 -->
                </p>
              </div>
              <div class="login-container" ref="login_box">
                <div class="section s_flex s_align_items s_justify_content">
                  <div style="width: 100%">
                    <el-form v-if="loginIndex == 1" ref="loginForm" :model="loginForm" :rules="loginRules"
                      class="login-form" autocomplete="on" label-position="left">
                      <div class="
                          title-container
                           s_flex s_align_items
                        ">
                        <h3 v-for="(item, index) in loginSize"
                          :style="{ 'marginRight': index == 0 ? '96px' : '', 'marginBottom': '-3px' }"
                          :class="loginIndexs == index ? 'Loginactive' : ''" :key="index" @click="getLogIndex(index)">
                          {{ item }}
                        </h3>
                      </div>
                      <div>
                        <div v-if="visitorStatus == 0">
                          <el-form-item prop="username">
                            <el-input ref="mobile" v-model="loginForm.mobile" placeholder="用户名" name="mobile" type="text"
                              tabindex="1" autocomplete="on">
                            </el-input>
                          </el-form-item>

                          <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
                            <el-form-item prop="password">

                              <el-input show-password :key="passwordType" ref="password" v-model="loginForm.password"
                                :type="passwordType" placeholder="密码" name="password" tabindex="2" autocomplete="on"
                                @keyup.native="checkCapslock" @blur="capsTooltip = false"
                                @keyup.enter.native="handleLogin">

                              </el-input>

                            </el-form-item>
                          </el-tooltip>

                          <el-form-item prop="code">
                            <div class="codeBox">
                              <el-input class="yanzhen" placeholder='验证码' v-model='loginForm.code'>
                              </el-input>
                              <canvas id="canvas" width="96px" height="44px" @click="handleCanvas"></canvas>
                            </div>
                          </el-form-item>


                          <el-row type="flex" justify="space-between">
                            <el-col>
                              <el-checkbox :checked="loginForm.checked" v-model="loginForm.checked" label="记住密码"
                                name="type"></el-checkbox>
                              <el-checkbox-group v-model="loginForm.type">
                              </el-checkbox-group>
                            </el-col>
                            <el-col style="text-align: right">
                            </el-col>
                          </el-row>

                          <el-button :loading="loading" class="pubBtn" style="width: 100%; margin: 20px 0 0 0;"
                            type="button" @click.native.prevent="handleLogin">登录</el-button>

                        </div>
                      </div>
                    </el-form>
                    <el-form v-else-if="loginIndex == 2" class="login-form login-formh" autocomplete="on"
                      label-position="left">
                      <div class="title-container">
                        <h3 class="title2">申请注册</h3>
                      </div>
                      <el-form-item>
                        <el-input v-model="registerArr.company" placeholder="请输入您的公司全称" type="text" tabindex="1" />
                      </el-form-item>

                      <el-form-item>
                        <el-input v-model="registerArr.username" placeholder="请输入您的姓名" type="text" tabindex="1" />
                      </el-form-item>

                      <el-form-item>
                        <el-input v-model="registerArr.mobile" placeholder="请输入您的手机号" maxlength="11" type="text"
                          tabindex="1" />
                      </el-form-item>

                      <el-form-item>
                        <el-input v-model="registerArr.email" placeholder="请输入您的邮箱" type="text" tabindex="1" />
                      </el-form-item>

                      <el-button :loading="loading" class="pubBtn tj" type="button" @click.native.prevent="Addregister">
                        提交</el-button>

                      <p class="context reg" @click="loginIndexUpdata">
                        立即登录 >
                      </p>
                    </el-form>

                    <el-form v-else-if="loginIndex == 3" class="login-form" autocomplete="on" label-position="left">
                      <div class="title-container">
                        <h3 class="title">找回密码</h3>
                      </div>

                      <el-form-item>
                        <el-input v-model="RetrieveArr.name" placeholder="姓名" type="text" tabindex="1" />
                      </el-form-item>

                      <el-form-item>
                        <el-input maxlength="11" v-model="RetrieveArr.mobile" placeholder="手机号" type="text"
                          tabindex="1" />
                      </el-form-item>

                      <el-form-item>
                        <el-input v-model="RetrieveArr.code" placeholder="验证码" type="text" tabindex="1"
                          style="width: 100%">
                          <el-button slot="append" :disabled="!!smsT" @click="getSms">{{ smsT ? `${smsT}s` : "获取验证码" }}
                          </el-button>
                        </el-input>
                      </el-form-item>

                      <el-button :loading="loading" class="pubBtn" @click="Retrieve" type="button"
                        style="width: 100%; margin: 40px 0; height: 44px">找回密码</el-button>
                      <p class="context reg" @click="loginIndex = 1">
                        已想起密码,立即登录 >
                      </p>
                    </el-form>
                    <el-form v-else-if="loginIndex == 4" class="login-form" autocomplete="on" label-position="left">
                      <div class="title-container">
                        <h3 class="title2">提交成功</h3>
                      </div>
                      <p class="sz" style="color: #8f9bb3; line-height: 1.5">
                        您的申请已提交。我们将在两个工作日内与您联系，请确保手机通讯畅通。请扫描关注下方二维码获得申请进度及通知。
                      </p>
                      <img class="ewm" src="../assets/web/ewm.png" alt="" />
                      <el-button class="pubBtn" type="button" style="
                          width: 100%;
                          margin: 10px auto 20px;
                          height: 44px;
                        " @click="loginIndex = 1">关闭</el-button>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </el-col>
      </el-row>
    </div>

    <div class="filings" @click="ICP">版权所有 © 2017-2021 广州市优专网络科技有限公司 ｜ 粤ICP备2022132806号</div>
  </div>
</template>
<script>
import { validUsername } from "@/utils/validate";
import { register, getSmsCode, forget,login,loginGJ } from "@/api/user";
import {
  ZF_URL,
  ZY_URL
} from "../config";
export default {
  components: {
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      // if (value.length < 6) {
      //   callback(new Error("请输入密码"));
      // } else {
        callback();
      // }
    };
    return {
      mbStatus: document.documentElement.clientWidth > 750 ? 1 : 0,
      activeName: 0,
      smsT: 0,
      smsT2: 0,
      loginIndex: 1,
      visitorStatus: 0,
      identifyCode: "",
      thumb_s: true,
      mobiles: localStorage.getItem("mobile"),
      identifyCodes: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
      ], //根据实际需求加入自己想要的字符
      loginSize: ["企业登录", "管理登录"],
      // loginSize: ["管理登录"],
      loginIndexs: 0,
      loginForm: {
        mobile: "",
        password: "",
        checked: false,
        type: 1,
        code: '',
      },
      registerArr: {
        company: "",
        username: "",
        position: "",
        mobile: "",
        email: "",
      },
      RetrieveArr: {
        name: "",
        mobile: "",
        code: "",
      },
      loginRules: {
        mobile: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      visitor: {
        mobile: "",
        code: "",
        graphical: "",
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      thumb: localStorage.getItem("avatar"),
      errNumber: 0,

      true_code: '',
      yanzheng_arr: []
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.handleCanvas()

    // if (this.thumb) {
    //   this.visitorStatus = 3;
    // } else {
    //   this.visitorStatus = 0;
    // }
    this.refreshCode();
    const mobile = localStorage.getItem("mobile");
    const password = localStorage.getItem("password");
    this.$nextTick(() => {
      if (window.location.href.split("=")[1] == 1) {
        this.loginIndex = 1;
      } else if (window.location.href.split("=")[1] == 2) {
        this.loginIndex = 2;
      } else if (window.location.href.split("=")[1] == 3) {
        this.loginIndex = 3;
      }
    });

    if (mobile && password) {
      this.loginForm.checked = true;
      this.loginForm.mobile = mobile;
      this.loginForm.password = password;
    } else {
      this.loginForm.checked = false;
      this.loginForm.mobile = "";
      this.loginForm.password = "";
    }
  },
  methods: {
    ICP(){
      window.open('https://beian.miit.gov.cn/')
    },
    draw(show_num) {
      var canvas_width = document.querySelector("#canvas").clientWidth;
      var canvas_height = document.querySelector("#canvas").clientHeight;
      var canvas = document.getElementById("canvas"); //获取到canvas
      var context = canvas.getContext("2d"); //获取到canvas画图
      canvas.width = canvas_width;
      canvas.height = canvas_height;
      var sCode = "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
      var aCode = sCode.split(",");
      var aLength = aCode.length; //获取到数组的长度

      //4个验证码数
      for (var i = 0; i <= 3; i++) {
        var j = Math.floor(Math.random() * aLength); //获取到随机的索引值
        var deg = (Math.random() * 30 * Math.PI) / 180; //产生0~30之间的随机弧度
        var txt = aCode[j]; //得到随机的一个内容
        show_num[i] = txt.toLowerCase();// 依次把取得的内容放到数组里面
        var x = 10 + i * 20; //文字在canvas上的x坐标
        var y = 20 + Math.random() * 8; //文字在canvas上的y坐标
        context.font = "bold 23px 微软雅黑";

        context.translate(x, y);
        context.rotate(deg);

        context.fillStyle = this.randomColor();
        context.fillText(txt, 0, 0);

        context.rotate(-deg);
        context.translate(-x, -y);
      }
      //验证码上显示6条线条
      for (var i = 0; i <= 5; i++) {
        context.strokeStyle = this.randomColor();
        context.beginPath();
        context.moveTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.lineTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.stroke();
      }
      //验证码上显示31个小点
      for (var i = 0; i <= 30; i++) {
        context.strokeStyle = this.randomColor();
        context.beginPath();
        var x = Math.random() * canvas_width;
        var y = Math.random() * canvas_height;
        context.moveTo(x, y);
        context.lineTo(x + 1, y + 1);
        context.stroke();
      }

      //最后把取得的验证码数组存起来，方式不唯一
      var num = show_num.join("");
      // console.log(num);
      this.true_code = num
    },
    //得到随机的颜色值
    randomColor() {
      var r = Math.floor(Math.random() * 256);
      var g = Math.floor(Math.random() * 256);
      var b = Math.floor(Math.random() * 256);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    //canvas点击刷新
    handleCanvas() {
      this.draw(this.yanzheng_arr);
    },

    loginIndexUpdata(){
      this.loginIndex = 1
      this.$nextTick(() => {
        this.handleCanvas()
      });
      console.log(this.loginIndex)
    },


    getLogIndex(index) {
      this.loginIndexs = index;
      this.loginForm.type = index + 1;
      // this.loginForm.type = 2;
      if (index == 0) {
        this.visitorStatus = 0;
      }
      this.handleCanvas()
    },
    checkCapslock({ shiftKey, key } = {}) {
      if (key && key.length === 1) {
        if (
          (shiftKey && key >= "a" && key <= "z") ||
          (!shiftKey && key >= "A" && key <= "Z")
        ) {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === "CapsLock" && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          console.log(this.true_code.toLowerCase(), this.loginForm.code.toLowerCase(),this.true_code.toLowerCase() != this.loginForm.code.toLowerCase());
          //判断this.true_code是否和 this.loginForm.code相等  需要忽略大小写
          if (this.true_code.toLowerCase() != this.loginForm.code.toLowerCase()) {
            this.$message.error('验证码错误')
            this.handleCanvas()
            return
          }

          let timeout
          if (this.errNumber >= 5) {
            this.$message.error('密码错误次数过多，请稍后再试')
            //错误定时器
            timeout ? '' : timeout = setTimeout(() => {
              this.errNumber = 0
            }, 1000 * 60 * 5)
            return
          }
          this.loading = true;

          const { mobile, password, checked ,type } = this.loginForm;

          let loginFun 

          let userInfo

          if(type == 1){
            loginFun = loginGJ
            userInfo = {
              mobile: mobile.trim(), password: password 
            }
          }else if(type == 2) {
            loginFun = login
            userInfo = {
              login: mobile.trim(), password: password 
            }
          }

          loginFun({ ...userInfo , type: type }).then(res => {
              console.log(res);
              console.log(type);
              this.loading = false;
              let URl
              if(type == 1){
                URl = ZY_URL
              }else if(type == 2) {
                URl = ZF_URL
              }
              window.open(`${URl}/#/web/LoginZfZy?mobile=${this.loginForm.mobile}&password=${this.loginForm.password}`)
          }).catch(() => {
            this.loading = false;
            this.errNumber++;
            this.handleCanvas()
          });

          // this.$store.dispatch("user/login", this.loginForm).then((res) => {
          //   console.log(res);
          //   this.loading = false;
          //   localStorage.setItem("isNewHome", true);
          // }).catch(() => {
          //   this.loading = false;
          //   this.errNumber++;
          //   this.handleCanvas()
          // });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    visitorLogins() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/visitor", this.visitor)
            .then(() => {
              console.log(this.redirect, 'this.redirect');
              console.log(this.otherQuery, 'this.otherQuery');
              this.$router.push({
                path: this.redirect || "/",
                query: this.otherQuery,
              });
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    async getSms() {
      if (!this.RetrieveArr.mobile) {
        this.$message.error("请先输入手机号码");
        return;
      } else {
        try {
          await getSmsCode({
            mobile: this.RetrieveArr.mobile,
          });
          this.$message.success("验证码发送成功");
          this.smsT = 60;
          const smsid = setInterval(() => {
            this.smsT = this.smsT - 1;
            if (this.smsT == 0) {
              clearInterval(smsid);
            }
          }, 1000);
        } catch (e) {
          this.$message.error(e.message);
        }
      }
    },
    async getSmsVisitor() {
      if (!this.visitor.mobile) {
        this.$message.error("请先输入手机号码");
        return;
      } else if (this.visitor.graphical != this.identifyCode) {
        this.$message.error("图形验证码错误");
        return;
      } else {
        try {
          await getSmsCode({
            mobile: this.visitor.mobile,
          });
          this.$message.success("验证码发送成功");
          this.smsT2 = 60;
          const smsid = setInterval(() => {
            this.smsT2 = this.smsT2 - 1;
            if (this.smsT2 == 0) {
              clearInterval(smsid);
            }
          }, 1000);
        } catch (e) {
          this.$message.error(e.message);
        }
      }
    },
    async Addregister() {
      try {
        const { company, username, position, mobile, email } = this.registerArr;
        await register({
          company,
          username,
          position,
          mobile,
          email,
        });
        this.$message.success("申请成功");
        this.loginIndex = 4;
      } catch (e) {
        this.$message.error(e.message);
      }
    },
    async Retrieve() {
      try {
        const { name, mobile, code } = this.RetrieveArr;
        await forget({
          name,
          mobile,
          code,
        });
        this.$message.success("重置成功，你的新密码已发送至邮箱");
        this.loginIndex = 1;
      } catch (e) {
        this.$message.error(e.message);
      }
    },
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
    },
    Gorouter(url) {
      this.$router.push({
        path: url,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

.codeBox {
  display: flex;
  justify-content: space-between;
}

.guanjia {
  height: 100vh;
  background-color: #ecf1fc;
  overflow: hidden;
}

.sekeeper_banner {
  position: relative;
  height: 100%;

  .bac_img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //禁止点击
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .logoText {
    width: 590px;
    height: 106px;
    font-size: 51px;
    font-weight: bold;
    color: #2d66f5;
    line-height: 59px;
    position: absolute;
    top: 42px;
    left: 79px
  }

  .sekeeper_login {
    // width: 1200px;
    position: absolute;
    right: 261px;
    top: 164px;
    z-index: 2;

    p {
      margin-top: 20px;
    }
  }

  .banner {
    width: 100%;
  }

  .s_login {
    width: 461px;
    height: 518px;
    background: #ffffff;
    box-shadow: 0px 0px 21px 0px rgba(97, 97, 102, 0.23);
    border-radius: 10px;
    padding: 40px 50px;
  }

  .reg {
    color: #ca0010;
    text-align: center;
    cursor: pointer;
  }

  .wangji {
    color: #3478ff;
    cursor: pointer;
  }

  .login_items {
    margin-top: 30px;
  }
}

.Typebox {
  display: flex;
  // width: 691px;
  width: 562px;
  justify-content: space-between;
  position: absolute;
  top: 707px;
  right: 261px;

  .itemType {
    background-color: #ffffff;
    padding: 17px 20px 18px 19px;
    width: 127px;
    border-radius: 10px;
    cursor: pointer;

    .iconBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      .iconfont {
        font-size: 32px;
      }

      .icon {
        width: 32px;
        height: 32px;
      }

      .el-icon-arrow-right {
        font-size: 32px;
        font-weight: bold;
        color: #565b67;
        transition: all 0.21s;
        opacity: 0;
      }
    }

    .title {
      // margin-bottom: 12px;
      font-size: 18px;
      transition: all 0.21s;
      color: #202225;
    }

    .text {
      font-size: 14px;
      transition: all 0.21s;
      color: #7A8186;
    }
  }

  .itemType:hover {
    .title {
      color: #2D66F5;
    }

    .text {
      color: #2D66F5;
    }

    .el-icon-arrow-right {
      color: #2D66F5;
      opacity: 1;
    }
  }
}

.home_info_right {
  right: 15%;
}

.code_w {
  // width:90px;
}

@media (max-width: 1360px) {
  .home_info_right {
    right: 4%;
  }
}

@media (max-width: 1440px) {
  .home_info_right {
    right: 6%;
  }
}


$bg: #283443;
$light_gray: #1f2e4c;
$cursor: #1f2e4c;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

.filings {
  position: absolute;
  bottom: 5%;
  text-align: center;
  width: 100%;
  color: #8F9BB2;
  font-size: 14px;
  //小手
  cursor: pointer;
}

.pubBtn {
  background-color: #33BF92;
  color: #fff;
}

/* reset element-ui css */
.loginBg {
  width: 50%;

  // position: absolute;
  // left:12%;
  // top:50%;
  // transform: translateY(-50%);
  img {
    width: 100%;
  }
}

.login-container {
  position: relative;

  // padding: 80px 0;
  .tj {
    width: 100%;
    // margin: 40px 0;
    height: 44px;
  }

  .head {
    position: fixed;
    right: 380px;
    top: 50px;

    img {
      margin: 40px;
    }
  }

  // .section{
  //   display: flex;
  //   height:750px;
  // }
  .wangji {
    color: #3478ff;
    cursor: pointer;
  }

  .reg {
    color: #34bf92;
    text-align: center;
    cursor: pointer;
  }

  .el-form-item__content {
    background-color: #fff;
    width: 372px;
    height: 40px;
    border: 1px solid rgba(212, 219, 235, 1);
    border-radius: 4px;
    overflow: hidden;
  }

  .el-input {
    // display: inline-block;
    // width: 80%;
    height: 40px;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      // padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 40px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    // border: 1px solid rgba(255, 255, 255, 0.1);
    // background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}

$bg: #f7fbfe;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  // min-height: 100%;
  // width: 100%;
  // background-color: $bg;
  overflow: hidden;

  .close {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #8f9bb3;
  }

  .ewm {
    width: 225px;
    height: 225px;
    display: block;
    margin: 16px auto 0;
  }

  .login-form {
    width: 562px;
    padding: 50px 102px 55px 96px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 2px 11px 46px 0px rgba(0, 134, 254, 0.09);
    border-radius: 10px;
    max-width: 100%;
    overflow: hidden;
    background-color: #fff;
    box-sizing: border-box;
  }

 

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    // padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    line-height: 30px;
    display: inline-block;
    text-align: center;
  }

  .title-container {
    // width: 60%;
    display: flex;
    position: relative;
    margin-bottom: 44px;
    border-bottom: 3px solid #D3DBEB;

    h3 {
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
      text-align: left;
      padding-bottom: 20px;
      cursor: pointer;
    }

    .Loginactive {
      font-size: 22px;
      font-weight: 400;
      color: #2D66F5;
      line-height: 14px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #2D66F5;
      }
    }

    .title2 {
      font-weight: 400;
      font-size: 20px;
      color: #3478ff;
      text-align: left;
      // margin: 62px 0 30px 0;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  .sz {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
  }
}

.thumb_img {
  width: 79px;
  height: 79px;
  background: #fffefe;
  border: 1px solid #bccae4;
  border-radius: 39px;
  margin: 0 auto;

  img {
    width: 100%;
    padding: 10px;
  }
}

.icon {
  width: 20px;
  height: 15px;
}

.thumb_obx {
  text-align: center;

  p {
    margin-top: 20px;
  }
}

@media (max-width: 1440px) {
  .sekeeper_banner .logoText {
    width: 311px;
    font-size: 40px;
    top: 26px;
    left: 50px;
  }

  .sekeeper_banner .sekeeper_login {
    right: 50px;
    top: 30px;
  }

  .Typebox {
    right: 50px;
    top: 530px;
    width: 462px;

    .itemType {
      width: 105px;

      .title {
        font-size: 16px;
      }
    }
  }

  .login-container {
    .login-form {
      width: 462px;
      padding: 30px 82px 35px 76px;
    }
  }

}

@media (max-width: 1280px) {
  .sekeeper_banner .sekeeper_login {}


}

@media (max-width: 750px) {}
</style>