// prod
// export const VUE_APP_BASE_API = "https://patent.youpat.cn"
// export const PAGE_BASE_URL = "https://test.web.oramage.com"

// tesst
export const ZF_VUE_APP_BASE_API = "https://api.zf.youpat.cn/index.php/api"
export const ZY_VUE_APP_BASE_API = "https://api1.gj.youpat.cn"

export const PAGE_BASE_URL = "https://test.web.oramage.com"
export const ZF_URL = "http://zf.youpat.cn"
export const ZY_URL = "http://gj.youpat.cn"
