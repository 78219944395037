import request from '@/utils/request'
import requestNew from '@/utils/requestNew'
export function register(data) {
  return request({
    url: '/v1/user/register',
    method: 'post',
    data
  })
}

export function getSmsCode(data) {
  return request({
    url: '/v1/user/send-code',
    method: 'post',
    data
  })
}
export function forget(data) {
  return request({
    url: '/v1/user/forget',
    method: 'post',
    data
  })
}
// 登录
export function login(data) {
  return request({
    url: '/v1/user/login',
    method: 'post',
    data
  })
}

// 登录
export function loginGJ(data) {
  return requestNew({
    url: '/v1/user/login',
    method: 'post',
    data
  })
}